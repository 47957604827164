<template>
  <div>
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="depositoCard"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          icon="BarChart2Icon"
          :statistic="depositoAmount"
          statisticTitle="Nos últimos 30 dias"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="crescimentoCard"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          :icon="crescimentoColor == 'success' ? 'TrendingUpIcon' : 'TrendingDownIcon' "
          :statistic="crescimentoAmount"
          statisticTitle="Comparado ao mês anterior"
          :color="crescimentoColor"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base vs-con-loading__container"
        id="totalCard"
      >
        <statistics-card-line
          hideChart
          class="mb-base link"
          icon="ChevronsUpIcon"
          :statistic="totalAmount"
          statisticTitle="Desde o inicio do ano"
          color="success"
        />
      </div>
    </div>

    <transaction-list :type="'deposit'"></transaction-list>
  </div>
</template>

<script>
import TransactionList from './TransactionList.vue';
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  components: { TransactionList, StatisticsCardLine },
  name: "DepositList",
  data() {
    return {
      depositoAmount: 0,
      crescimentoAmount: 0,
      crescimentoColor: "success",
      totalAmount: 0,
    };
  },
  methods: {
    formatToBrl(value){
      return Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }).format(value);
    },
    formatPercent(value){
      const percent = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, style: 'percent' }).format(value);
      this.crescimentoColor = value > 0. ? "success" : "danger";
      return value > 0. ? "+" + percent : "-" + percent;
    },
    loadData() {
      let self = this;

      this.$vs.loading({
        container: "#crescimentoCard",
        scale: 0.5
      });

      this.$vs.loading({
          container: "#totalCard",
          scale: 0.5
      });

      this.$vs.loading({
        container: "#depositoCard",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/" + this.$store.state.AppActiveUser.company_id + "/report"})
        .then(data => {
          self.$vs.loading.close("#depositoCard > .con-vs-loading");
          //format to brl currency
          self.depositoAmount = this.formatToBrl(data.totalLast30days);

          self.$vs.loading.close("#crescimentoCard > .con-vs-loading");
          //calcular porcentagem com data.totalLast30days e data.totalMesAnterior
          let percent = ((data.totalLast30days - data.totalMesAnterior) / (data.totalMesAnterior + 1));
          self.crescimentoAmount = this.formatPercent(percent);

          self.$vs.loading.close("#totalCard > .con-vs-loading");
          self.totalAmount = this.formatToBrl(data.totalAnoValue);
        }).catch(() => {
          self.$vs.loading.close("#depositoCard > .con-vs-loading");
          self.$vs.loading.close("#crescimentoCard > .con-vs-loading");
          self.$vs.loading.close("#totalCard > .con-vs-loading");
        });
    }
  },
  mounted() {
    this.loadData()
  }
};
</script>

<style lang="scss" scoped></style>
